// Copyright aptihealth, inc. 2019 All Rights Reserved

import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Toolbar from "../../../components/Navigation/Toolbar";
import Layout from "../../../hoc/Layout/Layout";
import HomePage from "./HomePage";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { api } from "../../../APIRequests";
import { Footer } from "../../../component-library/Footer";
import ChangePassword from "../../../components/Authentication/ChangePassword";
import Settings from "../common/SettingsPage";
import {
    provider_behavioral,
    providerRoute,
    provider_pcp,
    provider_case_manager,
    provider_peer,
} from "../../../config/roles";
import A5ScreeningDetails from "../../../components/Common/A5ScreeningDetails";
import A5ScreeningList from "../../../components/Common/A5ScreeningList";
import AVCheck from "../../../components/Common/AVCheck";
import MessagesView from "../../../components/Common/MessagesView";
import PrivacyPolicy from "../../../components/Common/PrivacyPolicy";
import SystemCheck from "../../../components/Common/SystemCheck";
import TutorialScreens from "../../../components/Common/TutorialScreen";
import ActionSection from "../../../components/Navigation/Toolbar/ActionSection";
import DraftNotesTable from "../../../components/Provider/DraftNotes";
import MemberProfile from "../../../components/Provider/PatientView/MemberProfile";
import CareCommunicationForm from "../../../components/Provider/PatientView/MemberProfile/CareCommunication/CareCommunicationForm";
import { CareManagementNewForm } from "../../../components/Provider/PatientView/MemberProfile/CareManagement/CareManagementNewForm";
import CaseManagementForm from "../../../components/Provider/PatientView/MemberProfile/CaseManagement/CaseManagementForm";
import DischargeData from "../../../components/Provider/PatientView/MemberProfile/DischargeData";
import PrintNoteView from "../../../components/Provider/PatientView/MemberProfile/PrintNoteView";
import AddAppointment from "../../../components/Provider/ScheduleAvailability/AddAppointment";
import EditEvent from "../../../components/Provider/ScheduleAvailability/EditEvent";
import { getProviderProfile, getRole, isAuthorized } from "../../../redux/actions/auth";
import { redirectOnSignIn } from "../../../utils/redirectOnSignIn";
import VideoClient from "../../VideoClient";
import GAD7 from "../Patient/GAD7";
import PHQ9 from "../Patient/PHQ9";
import ViewFullNote from "../common/ViewFullNote";
import A5Report from "./A5Report";
import FileList from "./FileList";
import IBARReport from "./IBARReport";
import IPSRReport from "./IPSRReport";
import NewReport from "./NewReport";
import OBARReport from "./OBARReport";
import OPSRReport from "./OPSRReport";
import OnBehalfOfSchedule from "./OnBehalfOfSchedule";
import Patient from "./Patient";
import CareTeamList from "./PatientProfileView/CareTeamList";
import ClinicalAssessment from "./PatientProfileView/ClinicalAssessment";
import NewNote from "./PatientProfileView/NewNote";
import NewNoteV2 from "./PatientProfileView/NewNoteV2";
import ProgressNoteList from "./PatientProfileView/ProgressNoteList";
import Ratings from "./PatientProfileView/Ratings";
import ViewDraftNote from "./PatientProfileView/ViewDraftNote";
import ViewDraftNoteV2 from "./PatientProfileView/ViewDraftNoteV2";
import ViewNote from "./PatientProfileView/ViewNote";
import PatientsList from "./PatientsList";
import ProviderProfile from "./ProviderProfile";
import ReferralView from "./ReferralView";
import Reports from "./Reports";
import SchedulePageV2 from "./SchedulePageV2";
import mapLabelToComponent from "../common/mapLabelToComponent";
import { WaitlistForm } from "../../../components/Common/WaitlistForm/WaitlistForm";
import { AdvancedCareActivityNewForm } from "../../../components/Provider/PatientView/MemberProfile/AdvancedCareActivity/AdvancedCareActivityNewForm";
import { hasAccessToCreateAdvancedCareActivityNote } from "../../../components/Provider/PatientView/MemberProfile/AdvancedCareActivity/AdvancedCareActivityScheme";

class Provider extends Component {
    ROUTE_SECTION_OPTIONS = this.determineNavBarSelection();

    PROVIDER_BASE_REDIRECT_ROUTE =
        isAuthorized(provider_case_manager) || isAuthorized(provider_peer)
            ? `${providerRoute}/patient`
            : `${providerRoute}/home`;

    ACTION_SECTION_OPTIONS = [];

    RESTRICTED_FROM_PEER_AND_CASE_MANAGER = !(
        isAuthorized(provider_case_manager) || isAuthorized(provider_peer)
    );

    navbarConfiguration = {
        routeSection: {
            options: this.ROUTE_SECTION_OPTIONS,
        },
        customRenderSection: [
            {
                isComponent: true,
                render: ActionSection,
                componentProps: {
                    config: {
                        options: this.ACTION_SECTION_OPTIONS,
                    },
                },
            },
        ],
        selfSection: {},
    };

    state = {
        totalUnreadMessages: 0,
    };

    constructor(props) {
        super(props);
        this.state = {
            totalUnreadMessages: 0,
        };
    }

    componentDidMount() {
        redirectOnSignIn(this.props.history);
        this.props.getProviderProfile();

        this.updateUnreadCount();

        if (process.env.REACT_APP_ENABLE_MESSAGE_NOTIFICATIONS_UPDATE === "true") {
            let time = process.env.REACT_APP_ENABLE_MESSAGE_NOTIFICATIONS_UPDATE_REFRESH_TIME;
            this.notification = setInterval(() => {
                if (document.hidden) {
                    console.log(
                        "Document is currently hidden. Waiting to fetch unread message count.",
                    );
                    return;
                }
                this.updateUnreadCount();
            }, parseInt(time));
        }
    }

    componentWillUnmount() {
        if (this.notification) {
            clearInterval(this.notification);
        }
    }

    determineNavBarSelection() {
        if (isAuthorized(provider_pcp)) {
            return [
                {
                    label: "Dashboard",
                    icon: "NavMenu__dashboard-icon",
                    mobileIcon: "dashboard.svg",
                    route: this.props.url + "/home",
                    iconComponent: mapLabelToComponent("Dashboard"),
                    activeIconComponent: mapLabelToComponent("Dashboard", true),
                },
                {
                    label: "Patients",
                    icon: "NavMenu__patients-icon",
                    mobileIcon: "patients.svg",
                    route: this.props.url + "/patient",
                    iconComponent: mapLabelToComponent("Patients"),
                    activeIconComponent: mapLabelToComponent("Patients", true),
                },
                {
                    label: "Add New Patient",
                    icon: "NavMenu__patients-icon",
                    mobileIcon: "add_new.svg",
                    mobileOnly: true,
                    route: this.props.url + "/patient/add",
                    iconComponent: mapLabelToComponent("Patients"),
                    activeIconComponent: mapLabelToComponent("Patients", true),
                },
                {
                    label: "Reports",
                    icon: "NavMenu__reports-icon",
                    mobileIcon: "reports.svg",
                    route: this.props.url + "/reports",
                    iconComponent: mapLabelToComponent("Reports"),
                    activeIconComponent: mapLabelToComponent("Reports", true),
                },
                {
                    label: "Screening",
                    icon: "NavMenu__screening-icon",
                    mobileIcon: "screening.svg",
                    route: this.props.url + "/a5_screenings",
                    iconComponent: mapLabelToComponent("Screening"),
                    activeIconComponent: mapLabelToComponent("Screening", true),
                },
                {
                    label: "Settings",
                    icon: "NavMenu__settings-icon",
                    mobileIcon: "settings.svg",
                    route: this.props.url + "/settings",
                    iconComponent: mapLabelToComponent("Settings"),
                    activeIconComponent: mapLabelToComponent("Settings", true),
                },
            ];
        }

        if (isAuthorized(provider_case_manager) || isAuthorized(provider_peer)) {
            return [
                {
                    label: "Patients",
                    icon: "NavMenu__patients-icon",
                    mobileIcon: "patients.svg",
                    route: this.props.url + "/patient",
                    iconComponent: mapLabelToComponent("Patients"),
                    activeIconComponent: mapLabelToComponent("Patients", true),
                },
                {
                    label: "Reports",
                    icon: "NavMenu__reports-icon",
                    mobileIcon: "reports.svg",
                    route: this.props.url + "/reports",
                    iconComponent: mapLabelToComponent("Reports"),
                    activeIconComponent: mapLabelToComponent("Reports", true),
                },
                {
                    label: "Messages",
                    route: this.props.url + "/messages",
                    iconComponent: mapLabelToComponent("Messages"),
                    activeIconComponent: mapLabelToComponent("Messages", true),
                },
            ];
        }

        return [
            {
                label: "Dashboard",
                icon: "NavMenu__dashboard-icon",
                mobileIcon: "dashboard.svg",
                route: this.props.url + "/home",
                iconComponent: mapLabelToComponent("Dashboard"),
                activeIconComponent: mapLabelToComponent("Dashboard", true),
            },
            {
                label: "Patients",
                icon: "NavMenu__patients-icon",
                mobileIcon: "patients.svg",
                route: this.props.url + "/patient",
                iconComponent: mapLabelToComponent("Patients"),
                activeIconComponent: mapLabelToComponent("Patients", true),
            },
            {
                label: "Reports",
                icon: "NavMenu__reports-icon",
                mobileIcon: "reports.svg",
                route: this.props.url + "/reports",
                iconComponent: mapLabelToComponent("Reports"),
                activeIconComponent: mapLabelToComponent("Reports", true),
            },
            {
                label: "Calendar",
                route: this.props.url + "/schedule",
                iconComponent: mapLabelToComponent("Calendar"),
                activeIconComponent: mapLabelToComponent("Calendar", true),
            },
            {
                label: "Messages",
                route: this.props.url + "/messages",
                iconComponent: mapLabelToComponent("Messages"),
                activeIconComponent: mapLabelToComponent("Messages", true),
            },
        ];
    }

    async updateUnreadCount() {
        if (isAuthorized("provider:pcp")) {
            return;
        }

        const options = { params: { showLoader: false } };
        let count = await api.messaging.fetch_unread_message_count({ options });
        this.setState({
            totalUnreadMessages: count["unread_count"],
        });
    }

    render() {
        let baseUrl = this.props.url;
        let enableSearch = false;
        if (_.get(this.props, "profile.omnisearch_enabled")) {
            enableSearch = true;
            if (
                !this.navbarConfiguration.routeSection.options.some(
                    (option) => option.label === "Search",
                )
            ) {
                this.navbarConfiguration.routeSection.options.push({
                    label: "Search",
                    iconComponent: mapLabelToComponent("Search"),
                    activeIconComponent: mapLabelToComponent("Search", true),
                });
            }
            this.navbarConfiguration["customRenderSection"] = [
                {
                    isComponent: true,
                    render: ActionSection,
                    componentProps: {
                        config: {
                            options: this.ACTION_SECTION_OPTIONS,
                        },
                    },
                },
            ];
        } else {
            this.navbarConfiguration["customRenderSection"] = [
                {
                    isComponent: true,
                    render: ActionSection,
                    componentProps: {
                        config: {
                            options: this.ACTION_SECTION_OPTIONS,
                        },
                    },
                },
            ];
        }

        const showChangePassword = this.props.profile && !this.props.profile.idp;
        const providerType = this.props.profile?.provider_type;

        return (
            <Layout>
                <Toolbar
                    totalUnreadMessages={this.state.totalUnreadMessages}
                    navbarConfiguration={this.navbarConfiguration}
                    baseRoute={providerRoute}></Toolbar>

                <Switch>
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route
                            path={baseUrl + "/home"}
                            render={(props) => <HomePage {...props} {...this.props} />}
                        />
                    )}
                    <Route path={baseUrl + "/patient/add"} component={Patient} />
                    <Route
                        path={baseUrl + "/patient/:patientId/note/add/v2"}
                        component={NewNoteV2}
                    />
                    <Route path={baseUrl + "/patient/:patientId/note/add"} component={NewNote} />
                    <Route
                        path={baseUrl + "/patient/:patientId/note/:noteId/v2/draft"}
                        component={ViewDraftNoteV2}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/note/:noteId/draft"}
                        component={ViewDraftNote}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/note/:noteId/v2"}
                        component={ViewFullNote}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/export_notes"}
                        component={PrintNoteView}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/note/:noteId"}
                        component={ViewNote}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/note"}
                        component={ProgressNoteList}
                    />
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route
                            path={baseUrl + "/patient/:patientId/care_communication"}
                            component={CareCommunicationForm}
                        />
                    )}
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route
                            path={baseUrl + "/patient/:patientId/case_management"}
                            component={CaseManagementForm}
                        />
                    )}
                    {!isAuthorized(provider_peer) && (
                        <Route
                            path={baseUrl + "/patient/:patientId/care_management"}
                            component={CareManagementNewForm}
                        />
                    )}
                    {hasAccessToCreateAdvancedCareActivityNote(providerType) && (
                        <Route
                            path={baseUrl + "/patient/:patientId/advanced_care_activity"}
                            component={AdvancedCareActivityNewForm}
                        />
                    )}
                    <Route
                        path={baseUrl + "/patient/:patientId/clinical_assessment"}
                        component={ClinicalAssessment}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/profile/v2"}
                        component={MemberProfile}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/care_team"}
                        component={CareTeamList}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/report/ibar"}
                        component={IBARReport}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/report/ipsr"}
                        component={IPSRReport}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/report/obar"}
                        component={OBARReport}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/report/opsr"}
                        component={OPSRReport}
                    />
                    <Route path={baseUrl + "/patient/:patientId/report/a5"} component={A5Report} />
                    <Route path={baseUrl + "/patient/:patientId/report"} component={Reports} />
                    <Route path={baseUrl + "/patient/:patientId/ratings"} component={Ratings} />
                    <Route path={baseUrl + "/patient/:patientId/phq-9"} component={PHQ9} />
                    <Route path={baseUrl + "/patient/:patientId/gad-7"} component={GAD7} />
                    <Route
                        path={baseUrl + "/patient/:patientId/file"}
                        render={(props) => <FileList {...props} type={"patient"} />}
                    />
                    <Route
                        path={baseUrl + "/patient/:patientId/discharge/:dischargeId"}
                        component={DischargeData}
                    />

                    {isAuthorized(provider_behavioral) && providerType === "BEHAVIORAL_INTAKE" && (
                        <Route
                            path={baseUrl + "/patient/:patientId/waitlist_form"}
                            component={WaitlistForm}
                        />
                    )}
                    <Route path={baseUrl + "/patient/:patientId"} component={Patient} />
                    <Route path={baseUrl + "/patient"} component={PatientsList} />
                    <Route path={baseUrl + "/profile"} component={ProviderProfile} />
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route path={baseUrl + "/schedule/event"} component={EditEvent} />
                    )}
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route path={baseUrl + "/schedule/add"} component={AddAppointment} />
                    )}
                    {isAuthorized("provider:peer") || isAuthorized("provider:case_manager") ? (
                        <Route
                            path={baseUrl + "/schedule/:providerId"}
                            render={(props) => <SchedulePageV2 {...props} />}
                        />
                    ) : (
                        <Route
                            path={baseUrl + "/schedule/:providerId"}
                            component={OnBehalfOfSchedule}
                        />
                    )}
                    {this.RESTRICTED_FROM_PEER_AND_CASE_MANAGER && (
                        <Route
                            path={baseUrl + "/schedule"}
                            render={(props) => <SchedulePageV2 {...props} />}
                        />
                    )}
                    {showChangePassword && (
                        <Route
                            path={baseUrl + "/change-password"}
                            render={(props) => (
                                <ChangePassword {...props} baseRoute={providerRoute} />
                            )}
                        />
                    )}
                    <Route
                        path={baseUrl + "/settings"}
                        render={() => <Settings baseRoute={providerRoute} />}
                    />
                    <Route
                        path={baseUrl + "/privacy-policy"}
                        render={() => <PrivacyPolicy baseRoute={providerRoute} />}
                    />
                    <Route
                        path={baseUrl + "/tutorial"}
                        render={() => <TutorialScreens baseRoute={providerRoute} />}
                    />
                    <Route path={baseUrl + "/system-check"} component={SystemCheck} />
                    <Route path={baseUrl + "/av-check"} component={AVCheck} />
                    <Route path={baseUrl + "/video-call"} component={VideoClient} />
                    <Route path={baseUrl + "/reports/add"} component={NewReport} />
                    <Route path={baseUrl + "/reports"} component={Reports} />
                    {!isAuthorized("provider:pcp") && (
                        <Route path={baseUrl + "/messages"} component={MessagesView} />
                    )}
                    {isAuthorized("provider:pcp") && (
                        <Route
                            path={baseUrl + "/a5_screenings/:screening_id/file"}
                            render={(props) => <FileList {...props} type={"a5_screenings"} />}
                        />
                    )}
                    {isAuthorized("provider:pcp") && (
                        <Route
                            path={baseUrl + "/a5_screenings/:screening_id"}
                            component={A5ScreeningDetails}
                        />
                    )}
                    {isAuthorized("provider:pcp") && (
                        <Route path={baseUrl + "/a5_screenings"} component={A5ScreeningList} />
                    )}
                    <Route
                        path={baseUrl + "/referral/:referralCode/file"}
                        render={(props) => <FileList {...props} type={"referral"} />}
                    />
                    <Route path={baseUrl + "/referral/:referralCode"} component={ReferralView} />
                    <Route path={baseUrl + "/draft-notes"} component={DraftNotesTable} />
                    <Redirect from={baseUrl} to={this.PROVIDER_BASE_REDIRECT_ROUTE} />
                </Switch>
                <Footer />
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.auth.profile,
    };
};

Provider.propTypes = {
    url: PropTypes.string,
    profile: PropTypes.object,
    history: PropTypes.object,
    getProviderProfile: PropTypes.func,
};

export default connect(mapStateToProps, { getProviderProfile })(withRouter(Provider));
