// Copyright aptihealth, inc. 2021 All Rights Reserved

import { api } from "../../APIRequests";
import { getFeatureFlagValue } from "../../utils/featureFlag";
import { initialConfigsState } from "../reducers/configsReducer";
import * as actionTypes from "./actionTypes";

const configsLoaded = (configs) => {
    return {
        type: actionTypes.CONFIGS_LOADED,
        configs: configs,
    };
};

export const loadConfigs = () => async (dispatch) => {
    let configs = await Promise.all(
        Object.keys(initialConfigsState).map(async (featureName) => {
            const queryParams = { feature_name: featureName };
            const options = { params: { showLoader: false } };
            let featureFlagValue;
            try {
                featureFlagValue = await api.public.get_feature_flag({ queryParams, options });
            } catch (e) {
                console.log(e);
                featureFlagValue = FEATURE_FLAG_DEFAULTS[featureName];
                console.log("Error retrieving feature flag, using default", featureFlagValue);
            }
            return {
                name: featureName,
                value: featureFlagValue,
            };
        }),
    );

    configs = configs.reduce((obj, feature) => {
        obj[feature.name] = getFeatureFlagValue(feature);
        return obj;
    }, {});

    dispatch(configsLoaded(configs));
};

/**
 *
 * !!! Remember to update these defaults when making changes to DB configs.
 *
 */

const UNINSURED_PRICES_DEFAULT = {
    diagnostic_interview: {
        first_session: "$300",
    },
    medication_management: {
        standard_sessions: "$48 - $155",
        medication_intake: "$165",
    },
    therapy: {
        add_thirty: "$55 - $70",
        forty_five_min: "$75 - $105",
        thirty_min: "$60 - $80",
        sixty_min: "$110 - $150",
    },
};

const POST_SESSION_SURVEY_DEFAULT = { enabled: true };

const PAYMENT_PAYWALL_DEFAULT = { enabled: true };

const OVERRIDE_RECAPTCHA_DEFAULT = false;

const NOTE_ADDENDUM_DEFAULT = { enabled: true };

const ENABLED_CARRIER_PAYWALL_DEFAULT = {
    enabled_carriers: {
        Aetna: false,
        "Anthem Medicare": false,
        "Highmark/BlueShield of Northeastern New York": false,
        Medicare: false,
        Anthem: false,
        "Humana Medicare": false,
        "No insurance": false,
        "United Healthcare Medicare": false,
        "Excellus Medicare": false,
        "United Healthcare Commercial": false,
        "Emblem Medicare": false,
        "United Healthcare Community Plan": false,
        "Insurance isn't on the list": false,
        Humana: false,
        Magnacare: false,
        Cigna: false,
        "Empire Plan (New York State)": false,
        "Excellus BlueCross BlueShield": false,
        "Humana Military/Tricare": false,
        "Aetna Medicare": false,
        "Wellcare/Today's Options": false,
        "Highmark/BlueShield of Northeastern New York \u2013 Medicare": false,
        "Martin's Point/MHACO (MaineHealth Accountable Care Organization)": false,
        "Emblem Health": false,
    },
};

/**
 *
 * !!! Remember to update these defaults when making changes to DB configs.
 *
 */

const FEATURE_FLAG_DEFAULTS = {
    OVERRIDE_RECAPTCHA: OVERRIDE_RECAPTCHA_DEFAULT,
    POST_SESSION_SURVEY: POST_SESSION_SURVEY_DEFAULT,
    UNINSURED_PRICES: UNINSURED_PRICES_DEFAULT,
    PAYMENT_PAYWALL: PAYMENT_PAYWALL_DEFAULT,
    NOTE_ADDENDUM: NOTE_ADDENDUM_DEFAULT,
    ENABLED_CARRIER_PAYWALL: ENABLED_CARRIER_PAYWALL_DEFAULT,
};
