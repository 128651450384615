// Copyright aptihealth, inc. 2021 All Rights Reserved
import { cloneDeep } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { deviceDetect } from "react-device-detect";
import { connect, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { api } from "../../../APIRequests";
import { Footer } from "../../../component-library/Footer";
import { A5_V2_ASSESSMENT_TYPE } from "../../../constants/assessments";
import { ERROR_CODES } from "../../../constants/errorCode";
import { showAlertWithAction } from "../../../redux/actions/alerts";
import { getLocalTimezone } from "../../../redux/reducers/authReducer";
import { trackPage } from "../../../utils/EventTrackingUtil";
import AddInsurance from "./WorkflowSteps/AddInsurance";
import CheckEmail from "./WorkflowSteps/CheckEmail";
import ConfirmPrimaryAccountInfo from "./WorkflowSteps/ConfirmPrimaryAccountInfo";
import PrimaryAccountInfo from "./WorkflowSteps/PrimaryAccountInfo";
import SecondaryAccountInfo from "./WorkflowSteps/SecondaryAccountInfo";
import "./style.scss";
import { tagReferralPath } from "../../../utils/logRocketUtils";
import Eligibility from "./WorkflowSteps/Eligibility";
import { featureFlagFunctionAsync } from "../../../utils/featureFlag";
import { OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG } from "../../../constants/featureFlags";
import { BrandedHeader } from "../../Common/BrandedHeader";

const SelfSignUp = (props) => {
    const [showSelfSignUp, setShowSelfSignUp] = useState(true);
    const [activeStep, setActiveStep] = useState("primary");
    const [accountData, setAccountData] = useState(cloneDeep(ACCOUNT_INITIAL_VALUES));
    const [insuranceEligibilityResponse, setInsuranceEligibilityResponse] = useState({});
    const [queryParams, setQueryParams] = useState("");

    useEffect(() => {
        tagReferralPath("patient_self_signup");
    }, []);

    const primaryAccountInfo = "primary"; //base account info (name, email, password, etc.)
    const confirmPrimaryAccountInfo = "confirm_primary";
    const secondaryAccountInfo = "secondary"; //address, emergency contacts, pcp info, etc.
    const addInsurance = "add_insurance"; //new insurance component
    const insuranceEligibility = "eligibility";
    const checkEmail = "check_email";

    const publicFlags = useSelector((state) => state.flags.publicFlags);
    const publicFlagsHaveLoaded = useSelector((state) => state.flags.publicFlagsHaveLoaded);

    const baseWorkFlowProps = {
        accountData,
        insuranceEligibility,
        setAccountData,
        setShowSelfSignUp,
        setInsuranceEligibilityResponse,
        insuranceEligibilityResponse,
    };

    const changeWorkFlowStep = (activeStep) => {
        window.scrollTo(0, 0);
        setActiveStep(activeStep);
        props.history.push(`/auth/self-signup/${activeStep}${queryParams}`);
    };

    const nextStepAfterInsurance = async (isCarrierSelected) => {
        await featureFlagFunctionAsync({
            flagName: OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG,
            enabled: publicFlags?.[OPERATIONAL_CHECK_INSURANCE_ELIGIBILITY_FLAG],
            hasLoaded: publicFlagsHaveLoaded,
            defaultEnabled: true,
            enabledBehavior: async () => {
                if (isCarrierSelected) {
                    changeWorkFlowStep(insuranceEligibility);
                } else if (await submitAccountData()) {
                    changeWorkFlowStep(checkEmail);
                }
            },
            alternateBehavior: async () => {
                if (await submitAccountData()) {
                    changeWorkFlowStep(checkEmail);
                }
            },
        });
    };

    const workflowSteps = {
        primary: (
            <PrimaryAccountInfo
                {...baseWorkFlowProps}
                history={props.history}
                nextStep={() => changeWorkFlowStep(confirmPrimaryAccountInfo)}
            />
        ),
        confirm_primary: (
            <ConfirmPrimaryAccountInfo
                {...baseWorkFlowProps}
                prevStep={() => changeWorkFlowStep(primaryAccountInfo)}
                nextStep={() => changeWorkFlowStep(secondaryAccountInfo)}
            />
        ),
        secondary: (
            <SecondaryAccountInfo
                {...baseWorkFlowProps}
                nextStep={() => changeWorkFlowStep(addInsurance)}
            />
        ),
        add_insurance: (
            <AddInsurance
                {...baseWorkFlowProps}
                prevStep={() => changeWorkFlowStep(secondaryAccountInfo)}
                nextStep={nextStepAfterInsurance}
            />
        ),
        eligibility: (
            <Eligibility
                {...baseWorkFlowProps}
                prevStep={() => changeWorkFlowStep(secondaryAccountInfo)}
                nextStep={async () => {
                    if (await submitAccountData()) {
                        changeWorkFlowStep(checkEmail);
                    }
                }}
            />
        ),
        check_email: <CheckEmail emailTo={accountData.email} />,
    };

    const isPageReload = () => !accountData.first_name;

    const submitAccountData = async () => {
        try {
            const modifiedAccountData = accountData;
            modifiedAccountData["Username"] = accountData["email"];
            modifiedAccountData["dob"] = moment(accountData.dob, "MMDDYYYY").format("MM/DD/YYYY");
            modifiedAccountData["group_type"] = "patient_self_signup";
            const consent = modifiedAccountData["consent"];
            modifiedAccountData["referrer_domain"] = document.referrer
                ? document.referrer
                      .replaceAll("http://", "")
                      .replaceAll("https://", "")
                      .replaceAll("localhost:3000", "")
                : null;

            modifiedAccountData["preferences"] = {
                is_sms_enabled: Boolean(consent?.sms),
                email_subscriptions: {
                    marketing_communications: Boolean(consent?.email),
                },
                time_zone: getLocalTimezone(),
            };

            modifiedAccountData["secondary_insurance"] = {};

            if (
                accountData.interested_in_guardianship &&
                accountData["primary_insurance"]["carrier"].length == 0
            ) {
                //if the parent doesn't have insurance, it implies they do not want to yet be a patient
                modifiedAccountData["assessment_config"] = {
                    skip_all: true,
                    skip_a5: true,
                    skip_a30: true,
                    default_assessment: A5_V2_ASSESSMENT_TYPE,
                };
            } else {
                modifiedAccountData["assessment_config"] = {
                    default_assessment: A5_V2_ASSESSMENT_TYPE,
                };
            }

            const referralSourceOtherText = accountData["referral_source_other"]
                .trim()
                .toLowerCase()
                .replace(/ /g, "_")
                .replace(/[^a-z0-9_]/g, "");

            if (accountData["referral_source"] === "other" && referralSourceOtherText) {
                modifiedAccountData["referral_source"] = referralSourceOtherText;
            }

            if (accountData["referral_care_navigator_facility"] == "") {
                delete modifiedAccountData["referral_care_navigator_facility"];
            }

            const deviceInfo = deviceDetect();
            if (deviceInfo) {
                modifiedAccountData.registration_device_type = deviceInfo.isBrowser
                    ? "Desktop"
                    : "Mobile web";
            }

            setAccountData(modifiedAccountData);

            await api.auth.sign_up({ data: modifiedAccountData, showLoader: true });
            return true;
        } catch (e) {
            console.log(e);
            const error = ERROR_CODES.hasOwnProperty(e.message)
                ? ERROR_CODES[e.message]
                : ERROR_CODES["SignUpDefault"];
            props.showAlertWithAction(`${error.message} Code: ${error.code}`);
            return false;
        }
    };

    useEffect(() => {
        (async () => {
            let qp = props.location.search;
            setQueryParams(qp);
            if (
                !props.match.params.step ||
                (props.match.params.step &&
                    !workflowSteps.hasOwnProperty(props.match.params.step)) ||
                isPageReload()
            ) {
                props.history.push(`/auth/self-signup/primary${qp}`);
            } else {
                setActiveStep(props.match.params.step);
                trackPage();
            }
        })();
    }, [props.match.params.step]);

    return (
        <>
            <div style={{ overflowX: "hidden" }} className={"self-signup-content"}>
                <div>
                    <BrandedHeader className="self-signup-header" />
                    <div className={"self-signup-inner-content"}>
                        {showSelfSignUp && workflowSteps[activeStep]}
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default connect(null, { showAlertWithAction })(withRouter(SelfSignUp));

export const INITIAL_INSURANCE_VALUES = {
    carrier: "",
    member_id: "",
    group_id: "",
    policy_holder_name: "",
    card_front: "",
    card_back: "",
};

const ACCOUNT_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    dob: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
    address: {
        street: "",
        city: "",
        state: "",
        zip_code: "",
    },
    emergency_contact_person: "",
    emergency_contact_number: "",
    pcp_name: "",
    pcp_location: "",
    primary_insurance: cloneDeep(INITIAL_INSURANCE_VALUES),
    interested_in_guardianship: false,
    acknowledgements: {},
    referral_source: "",
    referral_care_navigator_facility: "",
    referral_source_other: "",
};
