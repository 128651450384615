import { api } from "../APIRequests";

const featureFlagsWithRules = ["SCHEDULING_CALENDAR_V2", "ENABLED_CARRIER_PAYWALL"];

export const getFeatureFlagValue = (feature) => {
    const flagValue = Boolean(feature.value?.enabled);
    if (featureFlagsWithRules.includes(feature.name)) {
        return feature.value;
    }

    return flagValue;
};

export const featureFlagFunctionAsync = async ({
    flagName,
    enabledBehavior,
    alternateBehavior,
    enabled,
    defaultEnabled,
    hasLoaded,
}) => {
    if (!hasLoaded) {
        return undefined;
    }

    const isEnabled = enabled || (enabled === undefined && defaultEnabled);

    try {
        const result = await featureFlagFunctionShared({
            isEnabled,
            enabledBehavior,
            alternateBehavior,
        });

        return result;
    } catch (error) {
        featureFlagHandleError(error, flagName, isEnabled);
    }
};

export const featureFlagFunction = ({
    flagName,
    enabledBehavior,
    alternateBehavior,
    enabled,
    defaultEnabled,
    hasLoaded,
}) => {
    if (!hasLoaded) {
        return undefined;
    }

    const isEnabled = enabled || (enabled === undefined && defaultEnabled);

    try {
        const result = featureFlagFunctionShared({
            isEnabled,
            enabledBehavior,
            alternateBehavior,
        });
        return result;
    } catch (error) {
        featureFlagHandleError(error, flagName, isEnabled);
    }
};

const featureFlagHandleError = (error, flagName, isEnabled) => {
    const data = { flag_name: flagName, enabled: isEnabled };
    const options = { params: { showLoader: false } };
    api.featureFlag.post_feature_flag_error({ data, options });
    throw error;
};

const featureFlagFunctionShared = ({ isEnabled, enabledBehavior, alternateBehavior }) => {
    // if the flag is enabled run the enabled behavior
    if (isEnabled) {
        const result = enabledBehavior();
        return result;
    }

    // default for disabled behavior
    let disabledBehavior = () => {};
    // if the flag is disabled run the disabled behavior
    if (alternateBehavior) {
        disabledBehavior = alternateBehavior;
    }

    const result = disabledBehavior();
    return result;
};
