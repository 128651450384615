// Copyright aptihealth, inc. 2019 All Rights Reserved
import React, { Component } from "react";
import { Link } from "react-router-dom";
import images from "../../../utils/images";
import { CardPrimary } from "../../UI/Card";
import CardHeading from "../../UI/Headings/CardHeading";
import { A5_DETAILS_INITIAL_VALUES } from "./a5ScreeningDetailsSchema";
import { api } from "../../../APIRequests";
import { cloneDeep } from "lodash";
import { getQueryParams } from "../../../utils/filters";
import { isUserAdmin } from "../../../redux/actions/auth";
import { getPracticeList } from "../A5ScreeningList";
import AssociatedFiles from "../../Provider/PatientView/AssociatedFiles";
import { getScreeningAssociatedFiles, viewScreeningAssociatedFile } from "../../../utils/fileUtil";
import "./styles.scss";

class A5ScreeningDetails extends Component {
    constructor(props) {
        super(props);
        const pcpPracticeId = getQueryParams("pcpPracticeId", props.location.search.trim());
        const a5Details = cloneDeep(A5_DETAILS_INITIAL_VALUES);
        if (pcpPracticeId && pcpPracticeId !== "all_practices") {
            a5Details["pcp_practice_id"] = pcpPracticeId;
        }
        this.state = {
            pcpPracticeId: pcpPracticeId,
            a5Details: a5Details,
            practiceOptions: null,
            providerOptions: null,
            files: [],
        };
    }

    componentDidMount = async () => {
        let existingPcpPracticeId = await this.getScreeningDetails();
        const files = await getScreeningAssociatedFiles(this.props.match.params.screening_id);
        this.setState({ files });
        if (isUserAdmin()) {
            this.setPracticeOptions(await getPracticeList("Primary Care", false, "display"));
        }
        this.getPracticeProviders(existingPcpPracticeId);
    };

    getPcpPracticeId = (existingPcpPracticeId) => {
        let pcpPracticeId;
        if (existingPcpPracticeId) {
            pcpPracticeId = existingPcpPracticeId;
        } else if (this.state.pcpPracticeId !== "all_practices") {
            pcpPracticeId = this.state.pcpPracticeId;
        }
        return pcpPracticeId;
    };

    getPracticeProviders = async (existingPcpPracticeId) => {
        try {
            let response, pcpPracticeId;
            pcpPracticeId = this.getPcpPracticeId(existingPcpPracticeId);
            if (isUserAdmin() && pcpPracticeId) {
                response = await this.adminsGetPracticeProviders(pcpPracticeId);
            } else if (!isUserAdmin()) {
                response = await api.provider.fetch_practice_members();
            }
            if (response) {
                this.setProviderOptions(response);
            }
        } catch (e) {
            console.log(e);
        }
    };

    async adminsGetPracticeProviders(pcpPracticeId) {
        const urlParams = { practiceId: pcpPracticeId };
        return api.admin.fetch_practice_providers({ urlParams });
    }

    setPracticeOptions = async (options) => {
        this.setState({ practiceOptions: options });
    };

    setProviderOptions = (response) => {
        const options = response
            .filter((member) => member.provider_type === "PCP")
            .map((member) => {
                return { value: member.username, display: member.name };
            });
        this.setState({ providerOptions: options });
    };

    async getScreeningDetails() {
        try {
            const queryParams = { screening_id: this.props.match.params.screening_id };
            const response = await api.shared.fetch_a5_screening_details({ queryParams });
            this.setState({ a5Details: response });
            return response["pcp_practice_id"];
        } catch (e) {
            console.log(e);
        }
    }

    renderDisabledView = () => {
        return (
            <div className="mx-auto px-0 ">
                <div className="container">
                    <div className="row">
                        {this.getDisabledFields(this.getPracticeName(), this.getProviderName())}
                        <div className="col-12 col-lg-6">
                            <div>
                                <label className="Label">Notification Type</label>
                                <p>{this.state.a5Details.notification_type}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getPracticeName() {
        let practiceName = "";
        if (isUserAdmin() && this.state.practiceOptions) {
            const practice = this.state.practiceOptions.find(
                (practice) => practice["value"] === this.state.a5Details.pcp_practice_id,
            );
            if (practice) {
                practiceName = practice.display;
            }
        }
        return practiceName;
    }

    getProviderName() {
        let providerName = "";
        if (this.state.providerOptions) {
            const provider = this.state.providerOptions.find(
                (provider) => provider["value"] === this.state.a5Details.pcp_id,
            );
            if (provider) {
                providerName = provider.display;
            }
        }
        return providerName;
    }

    getDisabledFields(practiceName, providerName) {
        return (
            <>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">First Name</label>
                        <p>{this.state.a5Details.first_name}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Last Name</label>
                        <p>{this.state.a5Details.last_name}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Date of Birth</label>
                        <p>{this.state.a5Details.dob}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Gender</label>
                        <p>{this.state.a5Details.gender}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Email</label>
                        <p>{this.state.a5Details.email}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Mobile</label>
                        <p>{this.state.a5Details.mobile}</p>
                    </div>
                </div>
                <h6 className="col-12">
                    <b>Address</b>
                </h6>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Street</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.street}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">City</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.city}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">State</label>
                        <p>{this.state.a5Details.address && this.state.a5Details.address.state}</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Zip Code</label>
                        <p>
                            {this.state.a5Details.address && this.state.a5Details.address.zip_code}
                        </p>
                    </div>
                </div>
                {isUserAdmin() && (
                    <div className="col-12 col-lg-6">
                        <div>
                            <label className="Label">Practice</label>
                            <p>{practiceName}</p>
                        </div>
                    </div>
                )}
                <div className="col-12 col-lg-6">
                    <div>
                        <label className="Label">Primary Care Provider</label>
                        <p>{providerName}</p>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return (
            <div>
                <div className="survey-back-link-wpr mx-auto fs-16 my-3 py-2">
                    <Link className="survey-back-link" to={"/app/a5_screenings"}>
                        <img className="img-fluid" src={images("./common/solidBckBtn.svg")} />
                        <p className="d-inline ml-2">Back to Screening List</p>
                    </Link>
                </div>
                <CardPrimary className="A5ScreeningDetails--card">
                    <div className="mx-md-2 p-0 p-md-2 mb-0">
                        <CardHeading text="Screening Details" className="fs-18" />
                    </div>
                    <hr className="m-0 d-none d-md-block" />
                    <br />
                    <div>{this.renderDisabledView()}</div>
                </CardPrimary>
                <AssociatedFiles
                    viewAllLink={`/app/a5_screenings/${this.state.a5Details.id}/file`}
                    fileList={this.state.files}
                    getFile={(key) => {
                        viewScreeningAssociatedFile(this.state.a5Details.id, key);
                    }}
                />
            </div>
        );
    }
}

export default A5ScreeningDetails;
