// Copyright aptihealth, inc. 2019 All Rights Reserved

import axios from "axios";
import store from "../redux/store";
import { logOut, refreshAccessToken } from "../redux/actions/auth";
import { hideLoader, showLoader } from "../redux/actions/loader";
import { api } from "../APIRequests";
import { addToast, toastMessageTypes } from "../redux/actions/toaster";
import { DENY_LIST_EXCEPTION_TYPE } from "../constants/errorCode";

const BASE_URL = process.env.REACT_APP_BASE_URL;

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

/**
 * This "http" axios instance send request without authentication token
 */
export const http = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    },
});

http.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    return request;
});

http.interceptors.response.use(
    (response) => {
        store.dispatch(hideLoader());
        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        let cleanError = (error.response && error.response.data.error) || error;
        return Promise.reject(cleanError);
    },
);

/**
 * This "httpAuth" axios instance send request with authentication token
 */
export const httpAuth = axios.create({
    baseURL: BASE_URL,
});

httpAuth.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    return requestHandler(request);
});

httpAuth.interceptors.response.use(
    (response) => {
        const state = store.getState();
        if (state.loader.isLoading && !state.loader.isMultiPartRequest) {
            store.dispatch(hideLoader());
        }
        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        return retryError(error);
    },
);

/**
 * This "httpBackground" axios instance send request with authentication token in the background
 */
export const httpBackground = axios.create({
    baseURL: BASE_URL,
});

httpBackground.interceptors.request.use((request) => requestHandler(request));

httpBackground.interceptors.response.use(
    (response) => response.data.data,
    (error) => retryError(error),
);

const requestHandler = (request) => {
    let accessToken =
        (request.params && request.params.accessToken) || window.localStorage.getItem("token");
    delete request["params"];

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    request.cancelToken = source.token;

    if (!accessToken) {
        console.log("Missing token, logging out.");

        // Doesn't abort immediately, this rejects the request after this function completes.
        source.cancel("Canceling request: missing token.");

        store.dispatch(logOut());
    }

    if (isHandlerEnabled(request)) {
        // Modify request here
        request.headers["Authorization"] = accessToken;
        request.headers["Accept"] = "application/json";
        request.headers["Content-Type"] = "application/json";
        request.headers["x-location-path-name"] = window.location.pathname;
    }
    return request;
};

const isHandlerEnabled = (config = {}) => {
    return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled ? false : true;
};

const onAccessTokenFetched = (access_token) => {
    subscribers = subscribers.filter((callback) => callback(access_token));
};

const addSubscriber = (callback) => {
    subscribers.push(callback);
};

/**
 * This "httpWorkflowAuth" axios instance send request with authentication token from global variables
 */
export const httpWorkflowAuth = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

httpWorkflowAuth.interceptors.request.use((request) => {
    if (!(request.params && request.params.showLoader === false)) {
        store.dispatch(showLoader());
    }
    request.headers["Authorization"] = global.workflowAuthIdToken;
    return request;
});

httpWorkflowAuth.interceptors.response.use(
    (response) => {
        const state = store.getState();
        if (state.loader.isLoading && !state.loader.isMultiPartRequest) {
            store.dispatch(hideLoader());
        }

        return response.data.data;
    },
    (error) => {
        store.dispatch(hideLoader());
        return retryError(error, httpWorkflowAuth, refreshGlobalTokens);
    },
);

/**
 * Merges generic error config provided in the original request with more specific error type config
 * (e.g., "ForbiddenException"), errorTypeConfig. Missing values are filled in with defaults
 * @param cleanError Error received in the response
 * @param status Status code of the response
 * @param config Request configuration, i.e., the original request we send including additional
 *               fields
 * @returns {{[p: string]: *}} Configuration for the error type
 */
const getErrorConfig = (cleanError, status, config) => {
    const {
        errorTypeConfig,
        toastErrorType: defaultToastErrorType,
        conditionalExceptionReporting,
    } = config;
    const { type } = cleanError;
    const configForErrorType = errorTypeConfig?.[type];
    return {
        ...configForErrorType,
        toastErrorType: configForErrorType?.toastErrorType || defaultToastErrorType,
        toastDismissTimeout: configForErrorType?.toastDismissTimeout || 10000,
        preventToast: configForErrorType?.preventToast,
        allowRetry: configForErrorType?.allowRetry !== false,
        conditionalExceptionReporting,
    };
};

const retryError = (error, interceptor = httpAuth, refreshFunc = refreshLocalStorageTokens) => {
    const cleanError = (error.response && error.response.data.error) || error;
    const config = error["config"] || {};
    const response = error["response"] || {};
    const status = response["status"] || null;
    const originalRequest = config;
    const errorConfig = getErrorConfig(cleanError, status, config);

    if (config?.preventAllErrorToast) {
        errorConfig.preventToast = true;
    }

    if (status) {
        if (status === 403) {
            if (!isAlreadyFetchingAccessToken) {
                isAlreadyFetchingAccessToken = true;
                refreshFunc();
            }

            if (!errorConfig.allowRetry) {
                return Promise.reject(cleanError);
            }

            return new Promise((resolve) => {
                addSubscriber((access_token) => {
                    originalRequest.headers.Authorization = access_token;
                    resolve(interceptor(originalRequest));
                });
            });
        }

        if (cleanError?.type == DENY_LIST_EXCEPTION_TYPE) {
            // NOTE: this will cause https://aptihealth.atlassian.net/browse/AT1-4740
            // because we dont have access to the history within this context
            // confirmed that since the user is being banned this is fine
            // and spun up this ticket to address https://aptihealth.atlassian.net/browse/AT1-7039
            store.dispatch(logOut());
            return Promise.reject(cleanError);
        }

        if (isNonAuthFailure(status)) {
            handleNonAuthFailure(cleanError, status, errorConfig);
        }
    }

    return Promise.reject(cleanError);
};

const isNonAuthFailure = (status) => {
    return status !== 401 || status !== 403;
};

const handleNonAuthFailure = (cleanError, status, errorConfig) => {
    const { message, request_id: requestId } = cleanError;
    const {
        toastErrorType,
        toastDismissTimeout,
        preventToast,
        conditionalExceptionReporting,
        errorCallback,
    } = errorConfig;

    if (!preventToast && toastErrorType) {
        store.dispatch(
            addToast({
                message,
                messageType: toastErrorType,
                dismissTimeout: toastDismissTimeout,
            }),
        );
    } else if (!preventToast) {
        // Used to conditionally show exception reporting, i.e., "Report" button and request ID
        // inside the toast, based on status code. Show for 5XX codes, hide otherwise.
        const optionalRequestId = conditionalExceptionReporting
            ? status >= 500
                ? requestId
                : null
            : requestId;
        store.dispatch(
            addToast({
                message,
                messageType: toastMessageTypes.error_v2,
                dismissTimeout: toastDismissTimeout,
                requestId: optionalRequestId,
                hideReportButton: optionalRequestId === undefined,
            }),
        );
    }

    if (errorCallback) {
        errorCallback(cleanError);
    }
};

const refreshLocalStorageTokens = () => {
    store.dispatch(showLoader());
    store
        .dispatch(refreshAccessToken())
        .then((newIdAccessToken) => {
            isAlreadyFetchingAccessToken = false;
            store.dispatch(hideLoader());
            onAccessTokenFetched(newIdAccessToken);
        })
        .catch(() => {
            store.dispatch(hideLoader());
            isAlreadyFetchingAccessToken = false;
        });
};

const refreshGlobalTokens = () => {
    const data = {
        refresh_token: global.workflowAuthRefreshToken,
        id_token: global.workflowAuthIdToken,
    };
    api.auth
        .new_id_token({ data })
        .then((cleanResponse) => {
            global.workflowAuthIdToken = cleanResponse.AuthenticationResult.IdToken;
            global.workflowAuthAccessToken = cleanResponse.AuthenticationResult.AccessToken;
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(global.workflowAuthIdToken);
        })
        .catch((err) => {
            isAlreadyFetchingAccessToken = false;
            global.workflowAuthIdToken = null;
            global.workflowAuthAccessToken = null;
            global.workflowAuthRefreshToken = null;
        });
};
