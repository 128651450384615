import "./styles.scss";
import React, { Fragment } from "react";
import CardSecondary from "../../../../UI/Card/CardSecondary";
import {
    Button,
    ButtonTypes,
    capitalizeFirstLetter,
    Popover,
} from "../../../../../component-library";
import PropTypes from "prop-types";
import { OPERATIONAL_MANUAL_PAYMENT_PROCESSING } from "../../../../../constants/featureFlags";
import { useSelector } from "react-redux";
import FeatureGate from "../../../../Common/FeatureGate/FeatureGate";
import { List, ListBody, ListItem, ListTitleBar, TitleBarItem } from "../../../../UI/ListView";
import ListLoader from "../../../../Common/Loaders/ListLoader";
import moment from "moment";

const AVS_CODE_DESCRIPTIONS = Object.freeze({
    A: "Address (Street) matches, Zip does not",
    B: "Street address match - ZIP code does not match (international user)",
    C: "Street address and postal code in wrong formats",
    D: "Street address and postal code match (international user)",
    E: "AVS error",
    F: "Address does compare and 5-digit ZIP code does compare (UK only)",
    G: "Service not supported by non-US issuer",
    I: "Address information not verified by international user",
    M: "Street address and postal code match (international user)",
    N: "No match on address (street) or ZIP",
    O: "No response sent",
    P: "Postal codes match, street address not verified due to incompatible formats",
    R: "Retry / System Unavailable / Timed Out",
    S: "Service not supported by issuer",
    U: "Address information is unavailable",
    W: "9-digit ZIP matches, address (street) does not match",
    X: "Exact AVS match",
    Y: "Address (street) and 5-digit ZIP match",
    Z: "5-digit ZIP matches, address (street) does not match",
});

export const PaymentHistory = ({
    patientFirstName,
    openPaymentModal,
    hasCardOnFile,
    isCardExpired,
    transactionHistory,
    onLoadMore,
}) => {
    const OPERATIONAL_MANUAL_PAYMENT_PROCESSING_VALUE = useSelector(
        (state) => state.flags.privateFlags?.[OPERATIONAL_MANUAL_PAYMENT_PROCESSING],
    );

    const privateFlagsHaveLoaded = useSelector((state) => state.flags.privateFlagsHaveLoaded);
    const payments = transactionHistory ? transactionHistory.items : [];

    const addBulletsToLastFourDigits = (lastFourDigits) => {
        return lastFourDigits ? `\u2022\u2022\u2022\u2022${lastFourDigits}` : "";
    };

    const getStatusResult = (result, transaction_result) => {
        if (result === "SUCCESS" && transaction_result === "APPROVAL") {
            return "SUCCESS";
        } else if (result === "FAILURE" && !transaction_result) {
            return "FAILED";
        } else {
            if (transaction_result?.includes("DECLINED:")) {
                // For cases like DECLINED:DECLINED:BLOCKED OR DECLINED:CLOSED
                // that don't contain space after ":", we are adding a space between words,
                // so we don't need to apply too much css styles.
                return transaction_result.replace(/:(?=\w)/g, ": ");
            } else {
                return transaction_result;
            }
        }
    };

    const renderAvsErrorCode = (payment) => {
        const { avs_response, result, transaction_id } = payment;
        const tooltipId = `tooltip-${transaction_id}-avs`;
        const dataTip = AVS_CODE_DESCRIPTIONS[avs_response];

        if (result === "SUCCESS" || dataTip == null) {
            return <></>;
        }

        return (
            <div className={"PaymentHistory-avs_error_container"}>
                <span data-tip={dataTip} data-for={tooltipId}>
                    AVS Code: {avs_response}
                </span>{" "}
                {
                    <Popover
                        toolTipId={tooltipId}
                        className={"apti-Info--popover"}
                        position={"top"}>
                        {dataTip}
                    </Popover>
                }
            </div>
        );
    };

    const getListChildren = (payment) => {
        const handleCopyClick = (paymentId) => {
            navigator.clipboard.writeText(paymentId);
        };

        return (
            <>
                <div
                    className="row no-gutters d-lg-flex fs-14 align-items-top justify-content-between"
                    data-public={"true"}>
                    <span className="col-1 common-span">
                        {moment
                            .utc(payment.timestamp, "YYYY-MM-DD HH:mm:ss")
                            .local()
                            .format("MM/DD/YYYY")}
                    </span>
                    <span className="col-1 common-span">
                        {payment.transaction_amount && `$${payment.transaction_amount}`}
                    </span>
                    <span className="col-1 common-span">
                        {payment.event_type
                            ? capitalizeFirstLetter(
                                  payment.event_type.substring(0, 4).toLowerCase(),
                              )
                            : ""}
                    </span>
                    <span className="col-1 common-span">
                        {addBulletsToLastFourDigits(payment.payment_card_last_four_digits)}
                    </span>
                    <span
                        className={`col-1 ${
                            payment.result === "SUCCESS" ? "successful-status" : "failed-status"
                        }`}>
                        {getStatusResult(payment.result, payment.transaction_result)}
                        {renderAvsErrorCode(payment)}
                    </span>
                    <span className="col-1 common-span">
                        {payment.charged_by ? payment.charged_by : "System"}
                    </span>
                    <span className="col-2 common-span comment-col">{payment.comment}</span>
                    <span className="col-1 common-span">
                        {payment.transaction_id && (
                            <>
                                <a
                                    style={{ cursor: "pointer" }}
                                    className="link-style"
                                    data-tip="Copied to clipboard!"
                                    data-for={"tooltip-" + payment.transaction_id}
                                    data-event="click"
                                    data-event-off="blur">
                                    Copy ID
                                </a>{" "}
                                {
                                    <Popover
                                        toolTipId={"tooltip-" + payment.transaction_id}
                                        className={"apti-Info--popover"}
                                        position={"top"}
                                        afterShow={(e) => {
                                            e.preventDefault();
                                            handleCopyClick(payment.transaction_id);
                                        }}
                                        delayHide={1}>
                                        Copied to clipboard!
                                    </Popover>
                                }
                            </>
                        )}
                    </span>
                </div>
            </>
        );
    };

    let paymentListItems = (
        <>
            {payments &&
                payments.map((payment) => {
                    return <ListItem>{getListChildren(payment)}</ListItem>;
                })}
        </>
    );

    return (
        <CardSecondary className="PaymentHistory-card">
            <div data-public>
                <div className="PaymentHistory-header">
                    <div className="PaymentHistory-text_container">
                        <p className="PaymentHistory-title">Payment History</p>
                        <p className="PaymentHistory-subtitle">
                            This is a partial payment history for{" "}
                            <span data-private style={{ display: "inline" }}>
                                {patientFirstName}
                            </span>
                            . Charges processed by billing partners may not appear here.
                        </p>
                    </div>

                    {/* if the user can manually charge payments and the patient has a card on file */}
                    {/* show the button  */}
                    {hasCardOnFile && (
                        <FeatureGate
                            flagName={OPERATIONAL_MANUAL_PAYMENT_PROCESSING}
                            enabled={OPERATIONAL_MANUAL_PAYMENT_PROCESSING_VALUE}
                            alternateFlagDisabledBehavior={<></>}
                            hasLoaded={privateFlagsHaveLoaded}
                            // if the flag does not exist
                            // user should not be allowed to manually
                            // process payments
                            defaultFlagBehavior={false}>
                            <Button
                                // if the users card on file is expired
                                // disable the button
                                disabled={isCardExpired}
                                onClick={openPaymentModal}
                                className="PaymentHistory-button"
                                buttonType={ButtonTypes.primaryV2}>
                                Process New Payment
                            </Button>
                        </FeatureGate>
                    )}
                </div>
                <div className="PaymentHistory-line" />
            </div>

            {payments?.length > 0 ? (
                <div className="custom-list-body-wrapper">
                    <ListBody>
                        <ListTitleBar className="ListTitleBar-display">
                            <TitleBarItem classes="col-1 left-column" text="Date" />
                            <TitleBarItem classes="col-1 left-column" text="Amount" />
                            <TitleBarItem classes="col-1 left-column" text="Type" />
                            <TitleBarItem classes="col-1 left-column" text="Card" />
                            <TitleBarItem classes="col-1 left-column" text="Status" />
                            <TitleBarItem classes="col-1 left-column" text="Charged By" />
                            <TitleBarItem classes="col-2 left-column" text="Comment" />
                            <TitleBarItem classes="col-1 left-column" text="Trans. ID" />
                        </ListTitleBar>
                        <List>{payments ? paymentListItems : <ListLoader />}</List>
                    </ListBody>
                    {transactionHistory.last_evaluated_key !== null && (
                        <Button
                            className="LoadMore-button"
                            buttonType={ButtonTypes.primaryOutlineV2}
                            onClick={onLoadMore}>
                            <div className={"LoadMore-button-text"}>Load More</div>
                        </Button>
                    )}
                </div>
            ) : (
                <p data-public className="PaymentHistory-body_text">
                    No payments to show.{" "}
                </p>
            )}
        </CardSecondary>
    );
};

PaymentHistory.propTypes = {
    patientFirstName: PropTypes.string,
    openPaymentModal: PropTypes.func,
    transactionHistory: PropTypes.object,
    onLoadMore: PropTypes.func,
};
